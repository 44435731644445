import type { BoxProps } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';
import Link from 'next/link';
import type { FC, MouseEventHandler, ReactNode } from 'react';
import { memo } from 'react';

import { UNDERLINE_LINK_PROPS } from './underlineLinkProps';

interface ButtonProps {
  as: 'button';
  onClick: MouseEventHandler<any>;
  type: 'button' | 'submit';
}
interface AProps {
  as: 'a';
  href: string;
  onClick?: MouseEventHandler<any>;
  target?: '_blank';
}
interface LinkProps {
  as: 'link';
  children: ReactNode;
  href: string;
  target?: '_blank';
}

interface SharedProps {
  children: ReactNode;
  color?: BoxProps['color'];
  disabled?: boolean;
  fontSize?: string;
  fontWeight?: '400' | '500' | '600';
  isBold?: boolean;
  pb?: BoxProps['pb'];
  pl?: BoxProps['pl'];
  pos?: BoxProps['pos'];
  textStyle?: BoxProps['textStyle'];
}

export type UnderLineProps = (SharedProps & (ButtonProps | AProps)) | LinkProps;

export const UnderlinedLink: FC<UnderLineProps> = memo((props) => {
  if (props.as === 'link') {
    return (
      <Link href={props.href}>
        <Box {...UNDERLINE_LINK_PROPS} as="span">
          {props.children}
        </Box>
      </Link>
    );
  }
  // Note - inherits fontsize
  const { as, children, color, fontWeight, isBold, ...extProps } = props;
  return (
    <Box
      color={color}
      fontWeight={fontWeight ? fontWeight : isBold ? '600' : '400'}
      {...UNDERLINE_LINK_PROPS}
      {...extProps}
      as={as}
    >
      {children}
    </Box>
  );
});
