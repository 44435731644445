import type { FC } from 'react';
import { memo } from 'react';

import type { NumberProps } from './numberVariant';
import { numberVariants } from './numberVariant';

export const Four: FC<NumberProps> = memo(({ variant = 'orange' }) => {
  const { fill, stroke } = numberVariants[variant];
  return (
    <svg
      fill="none"
      height="134"
      viewBox="0 0 115 134"
      width="115"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M92.8683 133H93.8683V132V105.533H112.058H113.058V104.533V78.7583V77.7583H112.058H93.8683V2V1H92.8683H60.8857H60.4154L60.1154 1.36222L1.22978 72.4765L1 72.754V73.1143V104.533V105.533H2H59.8857V132V133H60.8857H92.8683ZM27.6676 77.7583L59.8857 39.5422V77.7583H27.6676Z"
        fill={fill}
        stroke={stroke}
        strokeWidth="2"
      />
    </svg>
  );
});
