import type { FC } from 'react';
import { memo } from 'react';

import type { NumberProps } from './numberVariant';
import { numberVariants } from './numberVariant';

export const Six: FC<NumberProps> = memo(({ variant = 'orange' }) => {
  const { fill, stroke } = numberVariants[variant];
  return (
    <svg
      fill="none"
      height="134"
      viewBox="0 0 108 134"
      width="108"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M62.2351 43.4343C48.9643 43.4343 40.1217 47.772 33.5671 53.6083V52.7166C33.746 44.729 35.3899 37.9966 38.7735 33.2853C42.1189 28.6271 47.2416 25.8354 54.6833 25.8354C60.1162 25.8354 63.5899 27.5515 65.8119 29.6913C68.0558 31.852 69.1063 34.5262 69.5273 36.547L69.6931 37.343H70.5062H101.433H102.651L102.414 36.1486C98.3523 15.6565 79.7241 1 55.2227 1C39.0682 1 25.4615 6.53147 15.9123 17.8525C6.38274 29.1502 1 46.0876 1 68.7082C1 85.003 4.34561 101.062 12.9648 113.074C21.6225 125.139 35.5153 133 56.3015 133C71.4254 133 83.8703 128.442 92.5427 120.3C101.221 112.152 106.029 100.502 106.029 86.509C106.029 62.2307 88.5082 43.4343 62.2351 43.4343ZM54.5035 108.165C47.9918 108.165 42.9615 106 39.5578 102.469C36.1479 98.9325 34.2863 93.937 34.2863 88.1272C34.2863 82.4111 36.1456 77.4605 39.5557 73.9445C42.9601 70.4344 47.9917 68.2697 54.5035 68.2697C60.9216 68.2697 65.9083 70.4319 69.2918 73.9422C72.6814 77.459 74.5408 82.4109 74.5408 88.1272C74.5408 93.9371 72.6791 98.9341 69.2897 102.472C65.9068 106.003 60.9215 108.165 54.5035 108.165Z"
        fill={fill}
        stroke={stroke}
        strokeWidth="2"
      />
    </svg>
  );
});
