import type { FC } from 'react';
import { memo } from 'react';

import type { NumberProps } from './numberVariant';
import { numberVariants } from './numberVariant';

export const One: FC<NumberProps> = memo(({ variant = 'orange' }) => {
  const { fill, stroke } = numberVariants[variant];

  return (
    <svg
      fill="none"
      height="134"
      viewBox="0 0 64 134"
      width="64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.1114 1H34.1114V2C34.1114 9.62548 30.5954 15.1035 24.8652 18.7214C19.085 22.3708 11.0162 24.1404 2 24.1404H1V25.1404V50.1621V51.1621H2C7.77588 51.1621 13.4363 50.7838 18.3109 49.8996C22.2837 49.1791 25.8171 48.1086 28.4674 46.5747V132V133H29.4674H61.4501H62.4501V132V2V1H61.4501H35.1114Z"
        fill={fill}
        stroke={stroke}
        strokeWidth="2"
      />
    </svg>
  );
});
