import type { FC } from 'react';
import { memo } from 'react';

import type { NumberProps } from './numberVariant';
import { numberVariants } from './numberVariant';

export const Five: FC<NumberProps> = memo(({ variant = 'orange' }) => {
  const { fill, stroke } = numberVariants[variant];
  return (
    <svg
      fill="none"
      height="134"
      viewBox="0 0 108 134"
      width="108"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M63.3112 41.8204C52.5849 41.8204 44.2925 44.7653 37.4952 49.5414L41.7006 28.1909H99.9024H100.902V27.1909V2V1H99.9024H19.5488H18.7335L18.5693 1.79856L3.85925 73.3261L3.61217 74.5276H4.83876H34.2588H34.7395L35.0397 74.1523C39.9745 67.9837 47.0257 65.3338 53.5658 65.3338C60.0779 65.3338 64.7379 67.2455 67.7851 70.6337C70.849 74.0404 72.4243 79.0961 72.4243 85.6634C72.4243 92.4769 70.5883 97.487 67.4582 100.789C64.3342 104.084 59.7963 105.809 54.1174 105.809C47.7034 105.809 42.9441 103.898 39.6859 100.963C36.4229 98.0246 34.5888 93.9908 34.1506 89.6091L34.0606 88.7086H33.1556H3H1.96256L2.00068 89.7454C2.46912 102.487 7.21112 113.341 16.2837 120.996C25.3412 128.639 38.6045 133 55.9561 133C70.196 133 82.8165 129.091 91.8926 121.217C100.989 113.326 106.419 101.543 106.419 86.0311C106.419 72.214 102.12 61.1383 94.5197 53.5146C86.9205 45.8917 76.1207 41.8204 63.3112 41.8204Z"
        fill={fill}
        stroke={stroke}
        strokeWidth="2"
      />
    </svg>
  );
});
