import type { BoxProps } from '@chakra-ui/react';
import { Box, Flex } from '@chakra-ui/react';
import { ICON_NUMBERS_LIST } from '@ours/web-icons';
import type { FC } from 'react';
import { memo } from 'react';

export interface Props {
  boxProps?: BoxProps;
  countdownSeconds: number;
}

export const Countdown: FC<Props> = memo(({ boxProps, countdownSeconds }) => {
  const ICON = ICON_NUMBERS_LIST[countdownSeconds - 1];

  if (!ICON) {
    throw new Error('MissingNumberIcon');
  }

  return (
    <Box
      bg="rgba(85, 84, 105, 0.6)"
      bottom="0"
      color="white"
      data-testid="Countdown"
      fontSize="10rem"
      pos="absolute"
      textAlign="center"
      top="0"
      width="full"
      zIndex="10"
      {...boxProps}
    >
      <Flex justifyContent="center" pos="absolute" top="35%" w="full">
        <ICON />
      </Flex>
    </Box>
  );
});
