import type { FC } from 'react';
import { memo } from 'react';

import type { NumberProps } from './numberVariant';
import { numberVariants } from './numberVariant';

export const Two: FC<NumberProps> = memo(({ variant = 'orange' }) => {
  const { fill, stroke } = numberVariants[variant];
  return (
    <svg
      fill="none"
      height="134"
      viewBox="0 0 102 134"
      width="102"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.9943 52.2786H34.9943V51.2786C34.9943 42.654 36.7691 36.9134 39.8335 33.3346C42.8699 29.7887 47.3257 28.1909 53.1174 28.1909C57.8812 28.1909 61.2566 29.5962 63.4548 32.0225C65.6683 34.4658 66.8274 38.097 66.8274 42.8204C66.8274 48.2873 64.4518 53.0727 60.2951 57.8135C56.1219 62.5732 50.2283 67.2044 43.3253 72.3586L43.3219 72.3611C29.5621 82.7268 18.9669 90.8035 11.8391 99.6783C4.65768 108.62 1 118.358 1 132V133H2H99.8218H100.822V132V106.809V105.809H99.8218H48.0468C50.9583 103.111 54.4296 100.457 58.1553 97.6084C59.5287 96.5583 60.9368 95.4817 62.3641 94.3667C71.0791 87.9451 80.6844 80.8497 88.0995 72.2259C95.5368 63.5762 100.822 53.3324 100.822 40.6139C100.822 28.2031 96.1091 18.2577 87.8398 11.4338C79.5928 4.62828 67.8998 1 54.0368 1C36.6566 1 23.8135 5.93015 15.3221 14.7764C6.83788 23.6151 2.83875 36.2168 2.83875 51.2786V52.2786H3.83875H33.9943Z"
        fill={fill}
        stroke={stroke}
        strokeWidth="2"
      />
    </svg>
  );
});
