import type { FC } from 'react';
import { memo } from 'react';

export const Seven: FC = memo(() => {
  return (
    <svg
      fill="none"
      height="128"
      overflow="visible"
      viewBox="0 0 94 128"
      width="94"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.192754 0.164992V25.51H60.6878V25.88C37.9328 45.305 20.5428 87.115 20.5428 128H53.8428C53.8428 88.595 64.9428 47.155 93.9878 23.66V0.164992H0.192754Z"
        fill="#FF7760"
      />
      <path
        d="M0.192754 0.164993V-1.83501H-1.80725V0.164993H0.192754ZM0.192754 25.51H-1.80725V27.51H0.192754V25.51ZM60.6878 25.51H62.6878V23.51H60.6878V25.51ZM60.6878 25.88L61.9863 27.4011L62.6878 26.8023V25.88H60.6878ZM20.5428 128H18.5428V130H20.5428V128ZM53.8428 128V130H55.8428V128H53.8428ZM93.9878 23.66L95.2456 25.215L95.9878 24.6146V23.66H93.9878ZM93.9878 0.164993H95.9878V-1.83501H93.9878V0.164993ZM-1.80725 0.164993V25.51H2.19275V0.164993H-1.80725ZM0.192754 27.51H60.6878V23.51H0.192754V27.51ZM58.6878 25.51V25.88H62.6878V25.51H58.6878ZM59.3892 24.3589C36.0799 44.257 18.5428 86.6904 18.5428 128H22.5428C22.5428 87.5396 39.7856 46.353 61.9863 27.4011L59.3892 24.3589ZM20.5428 130H53.8428V126H20.5428V130ZM55.8428 128C55.8428 88.8468 66.8883 48.1536 95.2456 25.215L92.7299 22.105C62.9972 46.1564 51.8428 88.3432 51.8428 128H55.8428ZM95.9878 23.66V0.164993H91.9878V23.66H95.9878ZM93.9878 -1.83501H0.192754V2.16499H93.9878V-1.83501Z"
        fill="black"
      />
    </svg>
  );
});
