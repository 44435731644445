import type { FC } from 'react';
import { memo } from 'react';

export const Eleven: FC = memo(() => {
  return (
    <svg
      fill="none"
      height="94"
      overflow="visible"
      viewBox="0 0 99 94"
      width="99"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.7987 0.714994C23.7987 12.19 13.1337 17.32 0.0387112 17.32V35.275C8.27371 35.275 16.1037 34.195 19.7487 31.36V94H42.6987V0.714994H23.7987ZM79.9608 0.714994C79.9608 12.19 69.2958 17.32 56.2008 17.32V35.275C64.4358 35.275 72.2658 34.195 75.9108 31.36V94H98.8608V0.714994H79.9608Z"
        fill="#FF7760"
      />
      <path
        d="M23.7987 0.714993V-1.28501H21.7987V0.714993H23.7987ZM0.0387111 17.32V15.32H-1.96129V17.32H0.0387111ZM0.0387111 35.275H-1.96129V37.275H0.0387111V35.275ZM19.7487 31.36H21.7487V27.2707L18.5208 29.7813L19.7487 31.36ZM19.7487 94H17.7487V96H19.7487V94ZM42.6987 94V96H44.6987V94H42.6987ZM42.6987 0.714993H44.6987V-1.28501H42.6987V0.714993ZM21.7987 0.714993C21.7987 5.71214 19.5316 9.24922 15.7616 11.6295C11.8915 14.0729 6.36968 15.32 0.0387111 15.32V19.32C6.80274 19.32 13.1609 18.002 17.8971 15.0118C22.7333 11.9583 25.7987 7.19286 25.7987 0.714993H21.7987ZM-1.96129 17.32V35.275H2.03871V17.32H-1.96129ZM0.0387111 37.275C4.23185 37.275 8.37501 37.0009 11.9719 36.3485C15.5104 35.7067 18.767 34.6573 20.9766 32.9387L18.5208 29.7813C17.0854 30.8977 14.6045 31.8058 11.258 32.4127C7.96992 33.0091 4.08057 33.275 0.0387111 33.275V37.275ZM17.7487 31.36V94H21.7487V31.36H17.7487ZM19.7487 96H42.6987V92H19.7487V96ZM44.6987 94V0.714993H40.6987V94H44.6987ZM42.6987 -1.28501H23.7987V2.71499H42.6987V-1.28501ZM79.9608 0.714993V-1.28501H77.9608V0.714993H79.9608ZM56.2008 17.32V15.32H54.2008V17.32H56.2008ZM56.2008 35.275H54.2008V37.275H56.2008V35.275ZM75.9108 31.36H77.9108V27.2707L74.6829 29.7813L75.9108 31.36ZM75.9108 94H73.9108V96H75.9108V94ZM98.8608 94V96H100.861V94H98.8608ZM98.8608 0.714993H100.861V-1.28501H98.8608V0.714993ZM77.9608 0.714993C77.9608 5.71214 75.6937 9.24922 71.9237 11.6295C68.0536 14.0729 62.5318 15.32 56.2008 15.32V19.32C62.9649 19.32 69.323 18.002 74.0592 15.0118C78.8954 11.9583 81.9608 7.19286 81.9608 0.714993H77.9608ZM54.2008 17.32V35.275H58.2008V17.32H54.2008ZM56.2008 37.275C60.394 37.275 64.5371 37.0009 68.134 36.3485C71.6725 35.7067 74.9291 34.6573 77.1387 32.9387L74.6829 29.7813C73.2475 30.8977 70.7666 31.8058 67.4202 32.4127C64.132 33.0091 60.2427 33.275 56.2008 33.275V37.275ZM73.9108 31.36V94H77.9108V31.36H73.9108ZM75.9108 96H98.8608V92H75.9108V96ZM100.861 94V0.714993H96.8608V94H100.861ZM98.8608 -1.28501H79.9608V2.71499H98.8608V-1.28501Z"
        fill="black"
      />
    </svg>
  );
});
