/**
 * The parent container of a position abs element w rounded corners will overflow on safari.
 *
 * This happens a lot to our countdown components inside of a video or photobooth frame.
 *
 * @see https://discourse.webflow.com/t/safari-not-hiding-overflow-on-rounded-corner-divs/55060
 * @see https://stackoverflow.com/questions/49066011/overflow-hidden-with-border-radius-not-working-on-safari
 * @see https://bugs.webkit.org/show_bug.cgi?id=140535
 * @see https://bugs.webkit.org/show_bug.cgi?id=98538
 */
export const safariOverflowRoundedCorner = {
  WebkitMaskImage: '-webkit-radial-gradient(white, black)',
};
