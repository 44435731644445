import { Eight } from './Eight';
import { Eleven } from './Eleven';
import { Fifteen } from './Fifteen';
import { Five } from './Five';
import { Four } from './Four';
import { Fourteen } from './Fourteen';
import { Nine } from './Nine';
import { One } from './One';
import { Seven } from './Seven';
import { Six } from './Six';
import { Ten } from './Ten';
import { Thirteen } from './Thirteen';
import { Three } from './Three';
import { Twelve } from './Twelve';
import { Two } from './Two';

export const ICON_NUMBERS_LIST = [
  One,
  Two,
  Three,
  Four,
  Five,
  Six,
  Seven,
  Eight,
  Nine,
  Ten,
  Eleven,
  Twelve,
  Thirteen,
  Fourteen,
  Fifteen,
];
