import type { FC } from 'react';
import { memo } from 'react';

import type { NumberProps } from './numberVariant';
import { numberVariants } from './numberVariant';

export const Three: FC<NumberProps> = memo(({ variant = 'orange' }) => {
  const { fill, stroke } = numberVariants[variant];
  return (
    <svg
      fill="none"
      height="134"
      viewBox="0 0 106 134"
      width="106"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.0844 43.9958H36.0844V42.9958C36.0844 37.3102 38.0096 33.5288 40.971 31.1432C43.9687 28.7284 48.1654 27.6335 52.8852 27.6335C57.7818 27.6335 61.3345 28.7717 63.651 30.7631C65.9428 32.7333 67.1687 35.6632 67.1687 39.5795C67.1687 44.5871 65.2359 48.0968 62.075 50.3901C58.8676 52.717 54.2926 53.8631 48.9295 53.8631H43.3555H42.3555V54.8631V75.9004V76.9004H43.3555H49.6487C56.6828 76.9004 61.9617 78.0206 65.4631 80.4527C68.8888 82.8323 70.7649 86.5782 70.7649 92.2628C70.7649 96.8474 69.5159 100.301 66.8376 102.634C64.1304 104.993 59.7841 106.367 53.2448 106.367C45.4805 106.367 40.3643 104.47 37.166 101.023C33.9534 97.5596 32.4882 92.3329 32.4882 85.2503V84.2503H31.4882H2H1V85.2503C1 100.396 5.8432 112.38 15.0222 120.564C24.1844 128.734 37.5333 133 54.3237 133C69.2893 133 81.7135 129.646 90.42 122.995C99.164 116.315 104.051 106.389 104.051 93.5214C104.051 86.1857 102.446 79.7233 98.8176 74.4565C95.4662 69.5916 90.4437 65.8266 83.5541 63.3245C93.0658 57.9358 100.455 49.3995 100.455 36.1632C100.455 24.8346 95.0971 16.0009 86.5416 10.0352C78.0157 4.09015 66.3374 1 53.6044 1C38.4906 1 26.2367 5.37195 17.7465 12.847C9.24688 20.3303 4.59613 30.859 4.59613 42.9958V43.9958H5.59613H35.0844Z"
        fill={fill}
        stroke={stroke}
        strokeWidth="2"
      />
    </svg>
  );
});
